import axios from 'axios';
// import React ,{useContext} from 'react'
// import { useNavigate } from 'react-router-dom';
// import { MessageContext } from '../src/components/MessageContext';

// const baseURL = process.env.REACT_APP_BASE_URL;

// const api = axios.create({
//   // admin.promisetracker.ng
//   // promise_tracker_backend.test
//   baseURL: 'https://promise_tracker_backend.test/api/v1/learner',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });





// api.interceptors.request.use((config) => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }

 
//   return config;
// });

// export default api;


const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: baseURL || 'https://promise_tracker_backend.test/api/v1/learner',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    const { status, data } = error.response || {};

  

    // Check for specific status codes and handle them accordingly
    if (status === 401) {
      // Unauthorized: handle token removal and redirection
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      console.error('Error 401: Unauthorized', data.message);
      // Optional: Redirect user to login page or show a message
     
    } else if (status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      // Forbidden: handle access denied
      console.error('Error 403: Forbidden', data.message);
    
  
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      // Generic error handling for other status codes
      console.error(`Error ${status}: ${data?.message || 'An error occurred'}`);
    }

    // Optionally, return a custom error message
    return Promise.reject(error);
  }
);

export default api;

// import axios from 'axios';
// import { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { MessageContext } from '../src/components/MessageContext';

// export const useApi = () => {
//   const navigate = useNavigate();
//   const { displayError } = useContext(MessageContext);

//   const api = axios.create({
//     baseURL: process.env.REACT_APP_BASE_URL || 'https://promise_tracker_backend.test/api/v1/learner',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });

//   // Attach token to each request if available
//   api.interceptors.request.use(
//     (config) => {
//       const token = localStorage.getItem('token');
//       if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//       }
//       return config;
//     },
//     (error) => {
//       displayError('Request error. Please try again.');
//       return Promise.reject(error);
//     }
//   );

//   // Handle response and display error messages
//   api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       const { status, data } = error.response || {};

//       if (status === 401) {
//         localStorage.removeItem('token');
//         localStorage.removeItem('userId');
//         displayError(data.message || 'Unauthorized. Please log in again.');
//         navigate('/');
//       } else if (status === 403) {
//         localStorage.removeItem('token');
//         localStorage.removeItem('userId');
//         displayError(data.message || 'Access forbidden.');
//         navigate('/');
//       } else {
//         localStorage.removeItem('token');
//         localStorage.removeItem('userId');
//         displayError(data.message || 'An unexpected error occurred.');
//       }

//       return Promise.reject(error);
//     }
//   );

//   return api;
// };


