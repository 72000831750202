import React ,{useState , useEffect ,useRef } from 'react'
import { Outlet ,Link } from 'react-router-dom'
import logo from '../../assets/logo.png';
import logoWhite from '../../assets/logo_white.png'
import footerImg from '../../assets/footer_img.png';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import styless from './Layout.module.css'; 
import Avatar from '../Protected/Avatar/Avatar';
import x from '../../assets/x.png';


const isAuthenticated = () => {
   return !!localStorage.getItem('token');
 };

export default function Layout() {

  const [isHovered, setIsHovered] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('Dashboard'); 
  const [firstInitials, setFirstInitials] = useState('');
  const [lastInitials, setLastInitails] = useState('');
  // const [userId , setUserId] = useState(null)
  const { user,logout ,initials} = useAuth();
  const navigate = useNavigate(); 

  const splitName = (name) => {

    if (typeof name !== 'string' || !name) {
      setFirstInitials('');
      setLastInitails('');
      return;
    }
    const nameParts = name.trim().split(' '); 
    
    setFirstInitials(nameParts[0]?.charAt(0).toUpperCase()); 
    setLastInitails(nameParts.length > 1 ? nameParts?.slice(1).join(' ').charAt(0).toUpperCase() : ''); 
  
  };

   const styles = {
      container: {
      //   height: '100vh',
        backgroundImage: `url(${footerImg})`,
      //   backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        width: '100%',  // Set your desired width
        height: '20rem',
        padding : '10rem',
      

      }
    };


      const menuIconRef = useRef(null);
      const navBarRef = useRef(null);
    
      useEffect(() => {
        const handleToggle = () => {
          menuIconRef.current.classList.toggle('bx-x');
          navBarRef.current.classList.toggle('active');
        };
   
        const menuIcon = menuIconRef.current;
    
        if (menuIcon) {
          menuIcon.addEventListener('click', handleToggle);
        }
    
        return () => {
          if (menuIcon) {
            menuIcon.removeEventListener('click', handleToggle);
          }
        };
      }, []);

      useEffect(() => {
         
        setActiveMenuItem('Dashboard');
      }, []);

      useEffect(()=> {
        // console.log(localStorage.getItem('userId'))
        splitName(localStorage.getItem('userId'))
      },[setFirstInitials,setLastInitails ])
    

    
    const handleLogout = async () => {
        //  const response =
          await logout();
         navigate('/');
      };

      const handleMenuClick = (menuItem) => {
        setActiveMenuItem(menuItem);
        menuIconRef.current.classList.remove('bx-x');
        navBarRef.current.classList.remove('active'); 
        
      };

  return (
    <div>

     {isAuthenticated() && 
      <nav className='navbar'>
        <div className="nav_logo" id="nav_logo">
           <img src={logo} alt='logo'/>
           <span>Learning Resource</span>
        </div>

        <i ref={menuIconRef} className='bx bx-menu sidebar-menu' id='sidebar-menu'></i>
        <div  ref={navBarRef}  className="menu header_menu" id="menu">
            <Link to='dashboard' 
            className={activeMenuItem === 'Dashboard' ? 'active' : ''}
            onClick={() => handleMenuClick('Dashboard')}
            
            >Dashboard <i className='bx bxs-dashboard' ></i></Link>
            <Link to='/courses'
            className={activeMenuItem === 'Courses' ? 'active' : ''}
            onClick={() => handleMenuClick('Courses')}
            
            >Pathway <i className='bx bxs-dashboard' ></i>

            </Link>
            
            <Link to='leaderboard'
             className={activeMenuItem === 'Leaderboard' ? 'active' : ''}
             onClick={() => handleMenuClick('Leaderboard')}
             
            
            >Leaderboard <i className='bx bxs-dashboard' ></i></Link>

            <span className='settingsIcon'>
            <Link to='profile-settings'
             className={activeMenuItem === 'ProfileSettings' ? 'active' : ''}
             onClick={() => handleMenuClick('ProfileSettings')}
             
            
            >Settings <i class='bx bxs-cog' ></i> </Link>

            <a className="logout" onClick={handleLogout}>LogOut</a>
            </span>


        </div>
        <div className="notification_box" id="notification_box">
             <span className="notification">
                  <i className='bx bxs-bell' ></i>
                   <span className='notification_count'>
                      <span className='notification_text'>2</span>
                   </span>
             </span>

             <span className='initials'
              onMouseEnter={() => setIsHovered(true)}
              
             
             >
              {firstInitials}{lastInitials}
              {/* {initials.firstInitial}{initials.lastInitial} */}
             </span>
            
             
             {isHovered && (
             <div className='logoutBox' onMouseLeave={() => setIsHovered(false)}>
                
                 <Link to='/profile-settings' >Settings</Link>
                 
                 <a  className="logout" onClick={handleLogout}>LogOut</a>
               
             </div>
             )}
        </div>
      </nav>
     }
     {<Outlet/>}

     {
      isAuthenticated() && 
      <footer className='footer'>
         
           <div className='footer_menu'>
             <div className="socials_box" id="socials_box">
                  <div className="nav_logo" id="nav_logo">
                        <img src={logoWhite} alt='logo'/>
                  </div>
                  <p>
                  The Promise tracker is dedicated to providing real-time updates, tracking progress, holding leaders accountable, ensuring transparency and fostering trust in governance. Stay informed on our latest updates
                  </p>
                  <div className='social_icons'>
                    <i class='bx bxl-linkedin'></i>
                    <a href='https://x.com/promisetrackers?t=w8CApu2EuLYA-IaFAY1dkg&s=09' target='_blank'>
                       <i class='bx bxl-twitter'></i>
                    </a>
                    
                    <a href='https://www.facebook.com/share/S2qsFuAWqAoLTyZZ/?mibextid=qi2Omg' target='_blank'>
                      <i class='bx bxl-facebook-circle'></i>
                    </a>
                    <a href='https://www.instagram.com/promisetrackers?igsh=cGVsM2pmZ2JxeGlq' target='_blank'>
                      <i class='bx bxl-instagram-alt' ></i>
                    </a>
                  </div>
             </div>
             <div className="menus">
                <div className="menu" id="menu">
                   <h3>Contact</h3>
                  <div className='link'>
                   <p>Address : 21 Akinsanya St, Ikeja, Ojodu, Lagos, Nigeria</p>
                   <p>Email : admin@advokc.ng </p>
                   <p>Call : 08137056530</p>
                  </div>

                </div>
                <div className="menu" id="menu">
                   <h3>About</h3>
                   <div className='link'>
                     <a>Our Story</a>
                     <a>Careers</a>
                     <a>Media Kit</a>
                   </div>
                </div>
                <div className="menu" id="menu">
                   <h3>Useful links</h3>
                   <div className='link'>
                     <a>Submit a promise</a>
                     <a>Climate Advokc</a>
                     <a>Advokc Foundation</a>
                     <a>Terms Of Service</a>
                   </div>
                </div>
             </div>
             <div className="newsletter" id="newsletter">
                 <h3>Newsletter</h3>
                 <p>Subscribe to our newsletter and be the first to receive updates on the promises we’re tracking and their progress.</p>
                 <div className="newsletter_form">
                  <form>
                     <input type='email'/>
                     <button>Submit</button>
                  </form>
                 </div>

             </div>
           </div>
           <div className={styless.footer_background_img} id="footer_img" ></div>
            <div className="copyright_box">
               <p>Copyright @Promise Tracker All Right Reserved 2024</p>
               <div className='faqs'>
                  <a>FAQ</a>
                  <a>Help Desk</a>
            </div>
          
         </div>
      </footer>
     }
   
    </div>
  )
}
